/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useDebouncedCallback } from 'use-debounce';
import { useEffect, useState, useMemo } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Box,
  Divider,
  InputAdornment,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

/**
 * The internal dependencies
 */
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';
import { useTranslation } from 'react-i18next';
import { useChangeApiFactory } from '../ApiClient';
import { useChangeStore } from '../store/changeStore';
import LoadSpinner from '../components/LoadSpinner/LoadSpinner';
import useLoading from '../utils/useLoading';
import LoadSpinnerSmall from '../components/LoadSpinner/LoadSpinnerSmall';
import { NotificationTypes } from '../types/notification';
import NotificationMessages from '../constants/notification';
import { useStorex } from '../store/UIStore';
import { isChangeManager } from '../utils/permissionHelper';
import ButtonsGroup from '../components/ButtonsGroup/ButtonsGroup';
import ChangeStatusDialogWrapper from '../components/Dialog/ChangeStatusDialogWrapper';
import { DialogType } from '../types/changes';
import { getDepartmentById } from '../utils/utils';
import LocalizationWrapper from '../components/Date/LocalizationWrapper';

const statusOptions = [{ title: 'Rejected' }, { title: 'Approved' }];

const ProjectExternalPage = observer(() => {
  const { change, isDataLoading, setUpdatedChange, workPackages } =
    useChangeStore();
  const { setNotification } = useStorex();
  const changeApi = useChangeApiFactory();
  const { isLoading, toggleIsLoading } = useLoading();

  const { t } = useTranslation();

  const isInternal = change?.preSelectInternalChange;

  const showButtonGroup =
    change?.status === UpdateChangeDtoStatusEnum.CalcComplete ||
    change?.status ===
      UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved;

  const [isDesktopDatePickerOpen, setIsDesktopDatePickerOpen] = useState(false);
  const [isExternalDatePickerOpen, setIsExternalDatePickerOpen] =
    useState(false);
  const [finalSalesValue, setFinalSalesValue] = useState(0);
  const [datePickerValue, setDatePickerValue] = useState(
    change?.approvalDateClient || null,
  );

  const [externalDatePickerValue, setExternalDatePickerValue] = useState(
    change?.externalApprovalCustomerDate || null,
  );

  const editAfterFields =
    change?.status === UpdateChangeDtoStatusEnum.CalcComplete ||
    change?.status ===
      UpdateChangeDtoStatusEnum.CrWorkpackagesInInternalProgress ||
    change?.status ===
      UpdateChangeDtoStatusEnum.ExternalChangeInternallyApproved ||
    change?.status === UpdateChangeDtoStatusEnum.Check;

  const NotificationMessage = NotificationMessages();

  const onDesktopDatePickerFocus = (e: any) => {
    setIsDesktopDatePickerOpen(true);
    e.target.blur();
  };
  const [modalType, setModalType] = useState<DialogType | null>(null);

  const onExternalDesktopDatePickerFocus = (e: any) => {
    setIsExternalDatePickerOpen(true);
    e.target.blur();
  };

  const isManager = isChangeManager(change);

  const affectedProcesses = useMemo(
    () => change?.processes.filter(process => !process.affected) || [],
    [change?.processes],
  );

  useEffect(() => {
    if (change) {
      if (change.approvalDateClient)
        setDatePickerValue(change.approvalDateClient);
      if (change.externalApprovalCustomerDate)
        setExternalDatePickerValue(change.externalApprovalCustomerDate);
    }
  }, [change]);

  const debouncedRequest = useDebouncedCallback(
    async (data: { [key: string]: string | number | null }) => {
      try {
        if (change && isManager) {
          toggleIsLoading();

          const { data: updatedChange } =
            await changeApi.changesControllerUpdate(change.id, {
              ...change,
              ...data,
              status: change.status as UpdateChangeDtoStatusEnum,
            });

          if (updatedChange) {
            setUpdatedChange({
              ...change,
              ...data,
              status: change.status as UpdateChangeDtoStatusEnum,
            });

            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.chagneUpdate,
            });
            toggleIsLoading();
          }
        }
      } catch (e: any) {
        setNotification({
          type: NotificationTypes.error,
          message: e.response.data.message || NotificationMessage.error,
        });
        toggleIsLoading();
        console.error(e);
      }
    },
    300,
  );

  useEffect(() => {
    const finalSalesValueFromProcesses =
      affectedProcesses &&
      affectedProcesses.reduce((acc, process) => {
        const department = getDepartmentById(
          process.pId,
          change?.commonProject?.navProcessToCommonProjectRelations,
        );
        if (
          process &&
          department &&
          department.hourlyRate &&
          process.releasedHours
        ) {
          return acc + department.hourlyRate * process?.releasedHours;
        }
        return acc + 0;
      }, 0);

    setFinalSalesValue(finalSalesValueFromProcesses);
  }, [finalSalesValue, change, affectedProcesses]);

  const onChange = (title: string, value: string | number | null) => {
    debouncedRequest({ [title]: value });
  };

  return (
    <Grid
      container
      direction="column"
      css={css`
        padding: 19px 0px 12px 0px;
      `}
    >
      <Grid
        container
        sx={{
          gap: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 3,
        }}
      >
        <Grid item>
          <Typography
            variant="body1"
            color="primary"
            css={css`
              width: fit-content;
              padding: 8px;
              background: #ececec;
              color: #333333;
              font-size: 12px;
            `}
          >
            {change?.preSelectInternalChange
              ? t('internText')
              : t('externText')}
          </Typography>
        </Grid>
        <Grid item>
          {change && showButtonGroup && (
            <ButtonsGroup
              isChangeExist={!!change}
              changeStatus={change?.status || ''}
              workPackages={workPackages}
              change={change}
              onClickBtn={e =>
                setModalType(e.currentTarget.dataset.type as DialogType)
              }
            />
          )}
        </Grid>
      </Grid>

      {isDataLoading && <LoadSpinner />}

      {!isDataLoading && (
        <Box
          css={css`
            display: flex;
            flex-direction: column;
            max-width: 90%;
            padding: 15px;
            border: 1px solid #ececec;
            box-shadow: 0px 3px 6px #ececec;
            position: relative;

            @media (max-width: 1000px) {
              max-width: 100%;
            }
          `}
        >
          <Typography
            variant="body1"
            color="primary"
            css={css`
              width: fit-content;
              padding: 8px;
              margin-bottom: 16px;
              color: #00223b;
              font-size: 14px;
              font-weight: 600;
              text-transform: uppercase;
            `}
          >
            {isInternal ? t('internalApprovalText') : t('externalApprovalText')}
          </Typography>

          {isLoading && <LoadSpinnerSmall />}
          <Grid container>
            <Grid item>
              <Box
                css={css`
                  display: flex;
                  flex-direction: column;
                  padding: 0 10px;
                `}
              >
                <TextField
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('quotationValue')}
                  defaultValue={change?.finalQuotationValue || ''}
                  disabled
                  variant="standard"
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },
                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />
                <TextField
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('materialValue')}
                  defaultValue={change?.materialValue || ''}
                  disabled
                  variant="standard"
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },

                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />

                <TextField
                  name="discountQuotationValue"
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('discountQuotationValue')}
                  defaultValue={change?.projectDiscount || 0}
                  onBlur={e =>
                    onChange(e.target.name, Number(e.target.value) || null)
                  }
                  variant="standard"
                  disabled
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },

                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />

                <TextField
                  variant="standard"
                  InputLabelProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          css={css`
                            margin-right: 16px;
                            font-size: 12px;
                            font-weight: 600;
                            color: #333333;
                          `}
                          variant="body1"
                          color="primary"
                        >
                          {t('totalQuotationValue')}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary"
                          css={css`
                            font-size: 14px;
                            font-weight: 300;
                            color: #333333;
                          `}
                        >
                          {(
                            Number(change?.finalQuotationValue) +
                            Number(change?.materialValue) -
                            Number(change?.projectDiscount)
                          ).toLocaleString(
                            localStorage.getItem('i18nextLng') || 'de',
                          )}
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      paddingLeft: '20px',
                    },
                  }}
                />

                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                <TextField
                  name="finalSalesValue"
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('sellValue')}
                  value={finalSalesValue}
                  variant="standard"
                  disabled
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },

                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />

                <TextField
                  name="salesMaterialValue"
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('salesMaterialValue')}
                  defaultValue={change?.salesMaterialValue || ''}
                  onBlur={e =>
                    onChange(e.target.name, Number(e.target.value) || null)
                  }
                  variant="standard"
                  disabled={!isManager || !editAfterFields}
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },

                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />

                <TextField
                  name="salesValueDiscount"
                  css={css`
                    .MuiInput-underline:before {
                      border-bottom: 1px solid #ececec;
                    }
                    .MuiInput-input {
                      padding: 4px 0 4px;
                    }
                    .MuiInputLabel-asterisk {
                      color: #ff0000;
                    }
                  `}
                  type="number"
                  label={t('discountSalesValue')}
                  defaultValue={change?.salesValueDiscount || ''}
                  onBlur={e =>
                    onChange(e.target.name, Number(e.target.value) || null)
                  }
                  variant="standard"
                  disabled={!isManager || !editAfterFields}
                  InputProps={{
                    style: {
                      paddingLeft: '16px',
                      paddingBottom: '6px',
                      marginBottom: '16px',
                      fontSize: '14px',
                      fontWeight: '300',
                    },

                    inputProps: { min: 0 },
                  }}
                  InputLabelProps={{
                    style: {
                      paddingLeft: '20px',
                      fontWeight: 600,
                      color: '#333333',
                    },
                  }}
                />

                <TextField
                  variant="standard"
                  InputLabelProps={{
                    style: {
                      display: 'none',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography
                          css={css`
                            margin-right: 16px;
                            font-size: 12px;
                            font-weight: 600;
                            color: #333333;
                          `}
                          variant="body1"
                          color="primary"
                        >
                          {t('totalSalesValue')}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary"
                          css={css`
                            font-size: 14px;
                            font-weight: 300;
                            color: #333333;
                          `}
                        >
                          {(
                            Number(finalSalesValue) +
                            Number(change?.salesMaterialValue) -
                            Number(change?.salesValueDiscount)
                          ).toLocaleString(
                            localStorage.getItem('i18nextLng') || 'de',
                          )}
                        </Typography>
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    readOnly: true,
                    style: {
                      paddingLeft: '20px',
                    },
                  }}
                />
              </Box>
            </Grid>
            {!isInternal &&
              change?.status !== UpdateChangeDtoStatusEnum.CalcComplete && (
                <Grid item>
                  <Box
                    css={css`
                      padding: 0 10px;
                    `}
                  >
                    <LocalizationWrapper>
                      <DesktopDatePicker
                        clearable
                        open={isExternalDatePickerOpen}
                        onOpen={() => setIsExternalDatePickerOpen(true)}
                        onClose={() => setIsExternalDatePickerOpen(false)}
                        label={t('externalApprovalCustomerDate')}
                        inputFormat="dd.MM.yyyy"
                        disabled={!isManager || !editAfterFields}
                        mask="__.__.____"
                        value={externalDatePickerValue}
                        onChange={newDate => {
                          if (
                            change &&
                            !change.externalApprovalCustomerDate &&
                            !newDate
                          ) {
                            return;
                          }
                          setExternalDatePickerValue(newDate);
                          onChange(
                            'externalApprovalCustomerDate',
                            newDate || null,
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            disabled={!isManager || !editAfterFields}
                            {...params}
                            variant="standard"
                            css={css`
                              margin-bottom: 16px;

                              .MuiInput-underline:before {
                                border-bottom: 1px solid #ececec;
                              }

                              .MuiInput-input {
                                padding-left: 16px;
                                padding-bottom: 11px;
                                font-size: 14px;

                                fontweight: '300';
                              }

                              .MuiButtonBase-root {
                                padding: 0 12px 2px 0;
                                background-color: #ffffff !important;

                                svg {
                                  width: 33px;
                                  height: 25px;

                                  path {
                                    fill: #80acd4;
                                  }
                                }
                              }
                            `}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              onFocus: е => onExternalDesktopDatePickerFocus(е),
                            }}
                            onClick={() => setIsExternalDatePickerOpen(true)}
                          />
                        )}
                      />
                    </LocalizationWrapper>
                    <LocalizationWrapper>
                      <DesktopDatePicker
                        clearable
                        open={isDesktopDatePickerOpen}
                        onOpen={() => setIsDesktopDatePickerOpen(true)}
                        onClose={() => setIsDesktopDatePickerOpen(false)}
                        label={
                          change?.statusClient === 'Approved'
                            ? t('approvalDateClient')
                            : t('rejectionDateClient')
                        }
                        inputFormat="dd.MM.yyyy"
                        disabled={!isManager || !editAfterFields}
                        mask="__.__.____"
                        value={datePickerValue}
                        onChange={newDate => {
                          if (
                            change &&
                            !change.approvalDateClient &&
                            !newDate
                          ) {
                            return;
                          }
                          setDatePickerValue(newDate);
                          onChange('approvalDateClient', newDate || null);
                        }}
                        renderInput={params => (
                          <TextField
                            disabled={
                              !isManager ||
                              change?.status !==
                                UpdateChangeDtoStatusEnum.CalcComplete
                            }
                            {...params}
                            variant="standard"
                            css={css`
                              margin-bottom: 16px;

                              .MuiInput-underline:before {
                                border-bottom: 1px solid #ececec;
                              }

                              .MuiInput-input {
                                padding-left: 16px;
                                padding-bottom: 11px;
                                font-size: 14px;
                                fontweight: '300';
                                width: 180px;
                              }

                              .MuiButtonBase-root {
                                padding: 0 12px 2px 0;
                                background-color: #ffffff !important;

                                svg {
                                  width: 33px;
                                  height: 25px;

                                  path {
                                    fill: #80acd4;
                                  }
                                }
                              }
                            `}
                            InputLabelProps={{
                              style: {
                                paddingLeft: '20px',
                                fontWeight: 600,
                                color: '#333333',
                              },
                            }}
                            inputProps={{
                              ...params.inputProps,
                              onFocus: е => onDesktopDatePickerFocus(е),
                            }}
                            onClick={() => setIsDesktopDatePickerOpen(true)}
                          />
                        )}
                      />
                    </LocalizationWrapper>
                    <Autocomplete
                      popupIcon={
                        <ExpandMoreIcon
                          color="primary"
                          css={css`
                            color: #80acd4;
                          `}
                        />
                      }
                      css={css`
                        icon: {
                          fill: 'red';
                        }
                        .MuiInput-underline:before {
                          border-bottom: 1px solid #ececec;
                        }
                        .MuiInputLabel-asterisk {
                          color: #ff0000;
                        }
                      `}
                      disabled={!isManager || !editAfterFields}
                      defaultValue={change?.statusClient || null}
                      options={statusOptions.map(option => option.title)}
                      onChange={(e, value) =>
                        onChange('statusClient', value || '')
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('clientStatus')}
                          variant="standard"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              paddingLeft: '16px',
                              paddingBottom: '6px',
                              marginBottom: '16px',
                              fontSize: '14px',
                              fontWeight: '300',
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              paddingLeft: '20px',
                              fontWeight: 600,
                              color: '#333333',
                            },
                          }}
                        />
                      )}
                    />

                    <TextField
                      name="rejectedStatusExplanation"
                      css={css`
                        .MuiInput-underline:before {
                          border-bottom: 1px solid #ececec;
                        }
                      `}
                      defaultValue={change?.rejectedStatusExplanation || ''}
                      onBlur={e => onChange(e.target.name, e.target.value)}
                      label={
                        change?.statusClient === 'Approved'
                          ? t('reason')
                          : t('RejectionReason')
                      }
                      variant="standard"
                      disabled={!isManager || !editAfterFields}
                      InputProps={{
                        style: {
                          paddingRight: '9px',
                          paddingBottom: '10px',
                          paddingLeft: '16px',
                          marginBottom: '16px',
                          fontSize: '14px',
                          fontWeight: '300',
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          paddingLeft: '20px',
                          fontWeight: 600,
                          color: '#333333',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              )}
          </Grid>
          <ChangeStatusDialogWrapper
            modalType={modalType}
            setModalType={m => setModalType(m)}
          />
        </Box>
      )}
    </Grid>
  );
});

export default ProjectExternalPage;
